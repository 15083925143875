<template>
  <el-form
    :model="addForm"
    :rules="rules"
    ref="addForm"
    label-width="142px"
    label-position="left"
    class="demo-form dialog-form dialog-form-inline"
  >
    <el-form-item label="配置名称：" prop="name">
      <el-input v-model.trim="addForm.name" placeholder="请输入配置名称"></el-input>
    </el-form-item>
    <el-form-item label="所属公司：" prop="deptId">
      <companySelect @getValue="getGroupId" />
    </el-form-item>
    <el-form-item></el-form-item>
    <el-form-item label="产品横坐标：" prop="prodAbscissa">
      <el-input v-model.trim="addForm.prodAbscissa" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="产品纵坐标：" prop="prodOrdinate">
      <el-input v-model.trim="addForm.prodOrdinate" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="产品名字字体大小：" prop="prodFont">
      <el-input v-model.trim="addForm.prodFont" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="公司横坐标：" prop="deptAbscissa">
      <el-input v-model.trim="addForm.deptAbscissa" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="公司纵坐标：" prop="deptOrdinate">
      <el-input v-model.trim="addForm.deptOrdinate" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="公司名字体大小：" prop="deptFont">
      <el-input v-model.trim="addForm.deptFont" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="二维码横坐标：" prop="qrAbscissa">
      <el-input v-model.trim="addForm.qrAbscissa" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="二维码纵坐标：" prop="qrOrdinate">
      <el-input v-model.trim="addForm.qrOrdinate" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="二维码大小比例：" prop="qrScale">
      <el-input v-model.trim="addForm.qrScale" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="二维码颜色深度：" prop="qrDark">
      <el-input v-model.trim="addForm.qrDark" placeholder="请输入"></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import { insertQrs, updateQrs, getQrsById } from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";

export default {
  components: {
    companySelect
  },
  props: {
    printId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      menuId: [],
      addForm: {
        "name": "测试配置名称12",
        "deptId": '',
        "deptAbscissa": 0,
        "deptFont": 0,
        "deptOrdinate": 0,
        "id": 0,
        "prodAbscissa": 0,
        "prodFont": 0,
        "prodOrdinate": 0,
        "qrAbscissa": 0,
        "qrDark": 0,
        "qrFont": 0,
        "qrHeight": 0,
        "qrOrdinate": 0,
        "qrScale": 0
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" },],
        deptId: [{ required: true, message: "请选择", trigger: "blur" },],
        deptAbscissa: [{ required: true, message: "请输入", trigger: "blur" },],
        deptFont: [{ required: true, message: "请输入", trigger: "blur" },],
        deptOrdinate: [{ required: true, message: "请输入", trigger: "blur" },],
        prodAbscissa: [{ required: true, message: "请输入", trigger: "blur" },],
        prodFont: [{ required: true, message: "请输入", trigger: "blur" },],
        prodOrdinate: [{ required: true, message: "请输入", trigger: "blur" },],
        qrAbscissa: [{ required: true, message: "请输入", trigger: "blur" },],
        qrDark: [{ required: true, message: "请输入", trigger: "blur" },],
        qrFont: [{ required: true, message: "请输入", trigger: "blur" },],
        qrHeight: [{ required: true, message: "请输入", trigger: "blur" },],
        qrOrdinate: [{ required: true, message: "请输入", trigger: "blur" },],
        qrScale: [{ required: true, message: "请输入", trigger: "blur" },],
      },
      companyList: []
    };
  },
  methods: {
    /**获取企业id */
    getGroupId (v) {
      this.addForm.deptId = v;
    },
    // 新增/保存用户
    onSave () {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let data = {
            "name": this.addForm.name,
            "deptId": this.addForm.deptId,
            "deptAbscissa": this.addForm.deptAbscissa,
            "deptFont": this.addForm.deptFont,
            "deptOrdinate": this.addForm.deptOrdinate,
            "prodAbscissa": this.addForm.prodAbscissa,
            "prodFont": this.addForm.prodFont,
            "prodOrdinate": this.addForm.prodOrdinate,
            "qrAbscissa": this.addForm.qrAbscissa,
            "qrDark": this.addForm.qrDark,
            "qrFont": this.addForm.qrFont,
            "qrHeight": this.addForm.qrHeight,
            "qrOrdinate": this.addForm.qrOrdinate,
            "qrScale": this.addForm.qrScale,
          };
          if (this.printId) {
            data.id = this.addForm.id;
            updateQrs(data).then(res => {
              if (res.code === 0) {
                this.$message.success("修改成功");
              } else {
                this.$message.error(res.message);
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          } else {
            insertQrs(data).then(res => {
              if (res.code === 0) {
                this.$message.success("新增成功");
              } else {
                this.$message.error(res.message);
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          }
        }
      });
    }
  },
  created () {
    if (this.printId) {
      getQrsById(this.printId).then(res => {
        if (res.code === 0) {
          this.addForm = { ...res.data };
        }
      });
    }
  },
  mounted () { }
};
</script>